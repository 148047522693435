import { Menu, Search, X } from "lucide-react";
import LogoMobile from "../../assets/svgs/logo-mobile.svg";
import LogoEinstein from "../../assets/svgs/logo-einstein.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shared/components/ui/avatar";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { UsersPermissionsUserEntity } from "../../generated/graphql";
import { Input } from "../../shared/components/ui/input";
import Container from "../../shared/components/Container";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import { mostrarModalLogin } from "../../shared/modals/ModalLogin";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "../../shared/components/ui/menu-bar";
import {
  removeLocalStorageValue,
  removeTokenFromCookie,
} from "../../shared/services/localStorage.service";
import { TOKEN_SECRECT } from "../../shared/constants/auth-types";
import { useValidacaoDadosUsuario } from "../../hooks/useValidacaoDadosUsuario";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../shared/components/ui/dialog";
import { Button } from "../../shared/components/ui/button";
import { twMerge } from "tailwind-merge";
import { UserDetalheContext } from "../../context/UserPreparaEinsteinContext";
import { ModalMenuMobile } from "../Header/components/ModalMenuMobile";
import { useModalPreparaEinstein } from "../../hooks/useModalPreparaEinstein";
import { HeaderPageModel } from "../../models/HeaderPageModel";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { Loader } from "../../shared/components";
import { mostrarModalFlowLogin } from "../../shared/modals/ModalFlowCadastro";

async function getHeaderPage(): Promise<HeaderPageModel> {
  const response = await fetchApi("menu/get-header-page", "GET").then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return {
        id: 1,
        logoUrl: "",
        menu: [],
      };
    }
  });
  return response;
}

interface SearchProps {
  q?: string;
}
export default function NewHeader({ q }: SearchProps) {
  const { user, setUser, loadingUser } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const { validadoDadosUsuario } = useValidacaoDadosUsuario();
  const history = useHistory();
  const location = useLocation();
  const gtm = useGtm();

  const [header, setHeader] = useState<HeaderPageModel>();

  const [novoUsuarioAtivo, setNovoUsuarioAtivo] = useState(false);
  let inputRef = useRef<any>(null);

  useEffect(() => {
    setNovoUsuarioAtivo(location.pathname.includes("novo-usuario-v2"));
    if (!location.pathname.includes("/oe/pesquisa") && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [location]);

  const loadHeaderData = async () => {
    const response = await getHeaderPage();
    setHeader(response);
  };

  useEffect(() => {
    loadHeaderData();
  }, []);

  const handleLogoutClick = () => {
    removeLocalStorageValue(TOKEN_SECRECT);
    removeTokenFromCookie();
    setUser(undefined);

    window.location.reload();
    window.location.href = "/";
  };

  useEffect(() => {
    const queryLocation = new URLSearchParams(location.search);
    const search = queryLocation.get("q");

    if (search) {
      inputRef.current.value = search;
    }
  }, [location?.search]);

  const _handleKeyDown = (e: any) => {
    const search = inputRef.current?.value;

    if (e.key === "Enter" || e.type === "click" || e === "click") {
      // GTM doc linha 45
      gtm.push(
        EDataLayerEventCategory.ADE_PESQUISA,
        EDataLayerEventAction.PREECHIMENTO,
        {
          label: "pesquisa-site",
          termo: search,
        },
      );

      history.push({
        pathname: "/oe/pesquisa",
        search: search ? `?q=${encodeURIComponent(search)}` : undefined,
      });
      setShowModal(false);
    }
  };

  const [toggleMenuMobile, setToggleMenuMobile] = useState(false);

  return (
    <div className="relative z-40 w-full bg-[#F5F8FF] lg:top-0">
      {!header && <HeaderBanner className="flex" user={user} />}
      <nav
        aria-label="Menu de navegação mobile"
        className="overflow-x-clip  headerWeb:hidden"
      >
        <div className="relative">
          <ul className=" flex h-[50px] items-center justify-between px-4 py-2">
            <li>
              <ul className="flex items-center gap-4">
                <li
                  onClick={() => {
                    setToggleMenuMobile(!toggleMenuMobile);
                  }}
                >
                  <Menu color="#004F92" strokeWidth={2.25} size={32} />
                </li>
                <li className="ml-[-10px]">
                  <Link to="/">
                    <h1 className="w-[130px] text-xs font-bold uppercase leading-3 text-[#004F92]">
                      academia digital{" "}
                      <span className="font-medium text-[#626466]">
                        einstein
                      </span>
                    </h1>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <ul className="flex items-center gap-4">
                <li className="ml-[25px]">
                  <Dialog
                    onOpenChange={(open) => setShowModal(open)}
                    open={showModal}
                  >
                    <DialogTrigger asChild>
                      <Button
                        data-testid="btn-pesquisa"
                        size="none"
                        type="button"
                        aria-label="Pesquisar"
                        className="flex items-center justify-center !rounded-lg !bg-[#004F92] !p-1"
                        aria-haspopup="dialog"
                      >
                        <Search color="#ffffff" strokeWidth={2.25} size={25} />
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="svg-white top-1/3 h-auto border-0 !bg-transparent py-12 shadow-none">
                      <Input
                        className="flex w-full rounded-xl"
                        icon={
                          <button
                            aria-hidden="true"
                            className=" flex size-9 cursor-pointer items-center justify-center rounded-lg bg-[#004F92]"
                            onClick={() => _handleKeyDown("click")}
                          >
                            <Search aria-hidden="true" color="white" />
                          </button>
                        }
                        ref={inputRef}
                        type="text"
                        name="search"
                        onKeyDown={_handleKeyDown}
                        placeholder="O que você está buscando?"
                      />
                    </DialogContent>
                  </Dialog>
                </li>
                <li>
                  {user ? (
                    <div className="relative">
                      <Avatar className="h-8 w-8">
                        <AvatarImage
                          src={user?.attributes?.Avatar?.data?.attributes?.url}
                          alt="@perfil"
                        />
                        <AvatarFallback className="border  bg-white-200 text-sm font-semibold text-brand-primary">
                          {user?.attributes?.nome_completo
                            ?.split(" ")
                            .slice(0, 2)
                            .map((name) => name[0])
                            .join("")}
                        </AvatarFallback>
                      </Avatar>
                      {!validadoDadosUsuario && (
                        <div
                          onClick={(ev) => {
                            ev.stopPropagation();
                            history.push("/meu-perfil");
                          }}
                          className="absolute -top-1 right-0 flex w-4 items-center justify-center rounded-full bg-red-600"
                        >
                          <div className="text-xxs font-extrabold text-white-200">
                            !
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        if (location.pathname === "/degustacao-pos") {
                          mostrarModalLogin(true);
                        } else {
                          mostrarModalLogin(false);
                        }

                        // GTM doc linha 34
                        gtm.push(
                          EDataLayerEventCategory.ADE_LOGIN,
                          EDataLayerEventAction.CLIQUE,
                          {
                            label: "login-topo",
                          },
                        );
                      }}
                      className="text-sm font-semibold text-[#004F92] "
                    >
                      Entre
                    </button>
                  )}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <nav
        aria-label="Menu de navegação desktop"
        className="hidden  overflow-x-clip headerWeb:block"
      >
        <div className=" relative bg-[#F5F8FF]">
          <Container className="bg-[#F5F8FF]">
            <ul className="flex items-center justify-between bg-[#F5F8FF] md:h-[87px] xl:flex xl:py-5 tablet:h-[80px]">
              <li className="w-[212px]">
                <Link to="/">
                  <h1 className="w-[189px] text-lg font-bold uppercase leading-22 text-[#004F92]">
                    academia digital{" "}
                    <span className="font-medium text-[#626466]">einstein</span>
                  </h1>
                </Link>
              </li>
              <li className="flex w-[528px] items-center justify-center ">
                <Input
                  className="ml-[15px] flex h-[40px]  w-[511px] rounded-xl border text-sm text-[#313335]"
                  icon={
                    <button
                      aria-hidden="true"
                      className="flex size-9 cursor-pointer items-center justify-center rounded-lg bg-[#004F92]"
                      onClick={() => _handleKeyDown("click")}
                    >
                      <Search aria-hidden="true" color="white" />
                    </button>
                  }
                  ref={inputRef}
                  type="text"
                  name="search"
                  onKeyDown={_handleKeyDown}
                  placeholder="O que você está buscando?"
                />
              </li>
              {!novoUsuarioAtivo ? (
                <li className="flex w-[212px] justify-end">
                  {!user ? (
                    <div className=" flex-col items-start justify-center text-start xl:flex">
                      <span className="text-xs font-normal leading-[14.63px] text-[#004F92]">
                        Bem-vindo(a)
                      </span>
                      <span className="flex gap-1 text-xs font-semibold leading-4 text-[#004F92]">
                        <button
                          className="cursor-pointer text-xs font-semibold hover:underline"
                          id="lk-entrar"
                          data-testid="btn-entre"
                          onClick={() => {
                            if (location.pathname === "/degustacao-pos") {
                              mostrarModalLogin(true);
                            } else {
                              mostrarModalLogin(false);
                            }
                            // GTM doc linha 34
                            gtm.push(
                              EDataLayerEventCategory.ADE_LOGIN,
                              EDataLayerEventAction.CLIQUE,
                              {
                                label: "login-topo",
                              },
                            );
                          }}
                        >
                          Entre
                        </button>
                        <span className="font-light">ou</span>
                        <p
                          data-testid="btn-crie-sua-conta"
                          onClick={() => {
                            mostrarModalFlowLogin(true);

                            gtm.push(
                              EDataLayerEventCategory.ADE_CADASTRO,
                              EDataLayerEventAction.CLIQUE,
                              {
                                label: "cadastro-topo",
                              },
                            );
                          }}
                          className="mb-0 cursor-pointer text-xs font-semibold hover:underline"
                        >
                          crie sua conta
                        </p>
                      </span>
                    </div>
                  ) : (
                    <div className="relative flex">
                      <Menubar>
                        <MenubarMenu aria-haspopup="menu">
                          <MenubarTrigger asChild>
                            <div className="flex !gap-5 font-normal hover:!font-bold focus:!font-bold tablet:!gap-3">
                              <span className="!text-sm  text-[#707070] hover:!font-bold focus:!font-bold">
                                {`Olá, ${user?.attributes?.nome_completo?.split(" ")[0]}`}
                              </span>
                              <Avatar className="h-14 w-14">
                                <AvatarImage
                                  src={
                                    user?.attributes?.Avatar?.data?.attributes
                                      ?.url
                                  }
                                  alt="@perfil"
                                />
                                <AvatarFallback className="border  bg-white-200 text-sm font-semibold text-brand-primary">
                                  {user?.attributes?.nome_completo
                                    ?.split(" ")
                                    .slice(0, 2)
                                    .map((name) => name[0])
                                    .join("")}
                                </AvatarFallback>
                              </Avatar>
                            </div>
                          </MenubarTrigger>
                          <MenubarContent className="">
                            <MenubarItem
                              data-testid="btn-meu-perfil"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                history.push("/meu-perfil");
                              }}
                              className="cursor-pointer font-semibold hover:bg-gray-100 hover:font-bold focus:font-bold"
                            >
                              Meu Perfil
                            </MenubarItem>
                            <MenubarSeparator />
                            <MenubarItem
                              data-testid="btn-deslogar"
                              onClick={(ev) => {
                                handleLogoutClick();
                              }}
                              className="cursor-pointer font-semibold hover:bg-gray-100 hover:font-bold focus:font-bold"
                            >
                              Deslogar
                            </MenubarItem>
                          </MenubarContent>
                        </MenubarMenu>
                      </Menubar>
                      {!validadoDadosUsuario && (
                        <button
                          onClick={(ev) => {
                            ev.stopPropagation();
                            history.push("/meu-perfil");
                          }}
                          aria-label="Atualize seu perfil"
                          className="absolute -top-1 right-3 flex w-4 items-center justify-center rounded-full bg-red-600"
                        >
                          <span className="text-xxs font-extrabold text-white-200">
                            !
                          </span>
                        </button>
                      )}
                    </div>
                  )}
                </li>
              ) : (
                <li className="flex w-[212px] justify-end"></li>
              )}
            </ul>
          </Container>
          <nav className="flex  h-[60px] w-full items-center bg-brand-primary">
            <Container className="flex w-full flex-row justify-between">
              <ul className="flex w-full items-center justify-between gap-8 tablet:gap-6">
                <li className="w-[28px]">
                  <Link to="/" aria-label="Voltar para a página inicial">
                    <img
                      src={LogoEinstein}
                      alt="Voltar para a página inicial"
                      height="28px"
                      style={{ height: "28px" }}
                    />
                  </Link>
                </li>
                {header?.menus?.map((menu, index) => (
                  <li key={index}>
                    <Link
                      to={menu?.path!}
                      className="text-white whitespace-nowrap text-xs font-semibold hover:font-bold focus:font-bold"
                      tabIndex={0}
                    >
                      {menu?.nome!}
                    </Link>
                  </li>
                ))}
              </ul>
            </Container>
          </nav>
        </div>
      </nav>
      {toggleMenuMobile && (
        <nav
          arial-label="Menu Mobile"
          className="z-[99999] overflow-x-clip"
          style={{
            zIndex: "99999 !important",
          }}
        >
          <div className="fixed left-0 top-0 z-[99999] h-screen w-full bg-[#004F92] p-6 xl:hidden">
            <div className="flex h-14 flex-row items-center justify-between">
              {!novoUsuarioAtivo ? (
                <>
                  {!user ? (
                    <div className=" flex-col items-start justify-center text-start xl:flex"></div>
                  ) : (
                    <div className="flex items-center justify-center !gap-2">
                      <Avatar className="h-10 w-10">
                        <AvatarImage
                          src={user?.attributes?.Avatar?.data?.attributes?.url}
                          alt="@perfil"
                        />
                        <AvatarFallback className="border  bg-white-200 text-sm font-semibold text-brand-primary">
                          {user?.attributes?.nome_completo
                            ?.split(" ")
                            .slice(0, 2)
                            .map((name) => name[0])
                            .join("")}
                        </AvatarFallback>
                      </Avatar>
                      <span className="!text-base  font-semibold text-white-200">
                        {`Olá, ${user?.attributes?.nome_completo?.split(" ")[0]}`}
                      </span>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex items-center justify-center !gap-2"></div>
              )}
              <button>
                <X
                  size={24}
                  color="#ffffff"
                  onClick={() => {
                    setToggleMenuMobile(!toggleMenuMobile);
                  }}
                />
              </button>
            </div>
            <div className="">
              <ModalMenuMobile
                data={header?.menus || []}
                setToggleMenuMobile={setToggleMenuMobile}
              />
            </div>
          </div>
        </nav>
      )}
    </div>
  );
}

export function HeaderBanner({
  className,
  user,
}: {
  className?: string;
  user?: UsersPermissionsUserEntity;
}) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { userDetalhe } = useContext(UserDetalheContext);
  const modalPreparaEinstein = useModalPreparaEinstein();
  const gtm = useGtm();

  const checkLocalStorage = async () => {
    try {
      const dataModal = JSON.parse(
        (await localStorage.getItem("PREPARA_EINSTEIN")) as any,
      ) as any;

      if (
        dataModal?.data_acesso_modal !== new Date().toLocaleDateString() ||
        !dataModal?.data_acesso_modal
      ) {
        modalPreparaEinstein.mostrar();
      }

      if (
        dataModal?.data_acesso_banner !== new Date().toLocaleDateString() ||
        !dataModal?.data_acesso_banner
      ) {
        setShow(true);
      }
    } catch (error) {}
  };

  const [localStorageChecked, setLocalStorageChecked] = useState(false);

  useEffect(() => {
    try {
      const profissaoID = user?.attributes?.PerfilProfissao?.data?.find(
        (item) => item.id,
      )?.id;

      if (
        user &&
        userDetalhe !== undefined &&
        !userDetalhe &&
        (profissaoID === "25" || (profissaoID === "10" && !localStorageChecked))
      ) {
        checkLocalStorage();
        setLocalStorageChecked(true); // Marca que o check foi realizado
      }
    } catch (err) {
      console.log(err);
    }
  }, [user, userDetalhe]);

  async function onClick() {
    const data = await localStorage.getItem("PREPARA_EINSTEIN");
    localStorage.setItem(
      "PREPARA_EINSTEIN",
      JSON.stringify({
        ...JSON.parse(data || "{}"),
        data_acesso_banner: new Date().toLocaleDateString(),
        data_acesso_modal: new Date().toLocaleDateString(),
      }),
    );

    gtm.push(
      EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_QUERO_COMECAR_INICIO,
      EDataLayerEventAction.CLIQUE,
      {
        label: "ir_para_inicio_prepara_einstein_inicio",
      },
    );

    setShow(false);
  }

  if (!show) {
    return null;
  }

  return (
    <div
      className={twMerge(
        "relative flex h-[226px]  w-full flex-row justify-between md:!h-[98px]",
        className,
      )}
      style={{
        background: "linear-gradient(180deg, #673FB4 0%, #602A95 100%)",
      }}
    >
      <Container className="flex flex-col items-center justify-center gap-3 py-6 text-center md:flex-row md:justify-between md:!text-start">
        <button className="absolute right-4 top-4" aria-label="Conheça agora">
          <X
            size={24}
            color="white"
            onClick={() => {
              onClick();
            }}
          />
        </button>
        <div className="flex flex-col items-center justify-center gap-2 tablet:items-start tablet:text-start">
          <span className="inline-block w-[207px] text-center text-lg font-bold leading-22 text-white-200 md:w-full md:!text-start xl:w-full tablet:!text-start">
            Prepara Einstein para Residência Médica.
          </span>
          <span className="w-[300px] !text-base font-normal leading-22 text-white-200 md:text-lg xl:w-full tablet:w-full">
            Uma Jornada de Estudos com foco em preparar você para as provas da
            Residência Médica.{" "}
          </span>
        </div>

        <Button
          data-testid="btn-conheca-agora"
          size="default"
          className="h-[50px] w-[194px] rounded-5xl bg-[#F5F8FF] text-base font-semibold text-[#004F92] hover:text-white-200"
          onClick={() => {
            history.push("/prepara-einstein");

            onClick();
          }}
          variant="primary"
        >
          Conheça agora
        </Button>
      </Container>
    </div>
  );
}
