import React, { useEffect, useState } from "react";

import { AreaConhecimento } from "../../generated/graphql";

import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderSection,
  Loader,
} from "../../shared/components";

import { AreasDeInteresse } from "../_HomePage/components";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { KnowledgeArea } from "../../shared/interfaces/area.interface";
import Container from "../../shared/components/Container";

export interface AreasInteresseLocationParams {
  profissao?: boolean;
}

const AreasInteressePage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<KnowledgeArea[]>([]);
  const gtm = useGtm();
  const scrollTop = useScrollTop();

  useEffect(() => {
    scrollTop();
    return () => {
      // GTM doc linha 64
      gtm.push(
        EDataLayerEventCategory.PAGE_VIEW,
        EDataLayerEventAction.NAVEGACAO,
        {
          label: "area_interesse",
        },
      );
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_ROOT}/api/area-de-conhecimentos/v2`, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    }).then(async (res: any) => {
      const areas = (await res.json()).results;
      setData(areas as unknown as KnowledgeArea[]);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <div>
        <HeaderSection>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem active>Áreas de interesse</BreadcrumbItem>
          </Breadcrumb>
          <h3 className="text-white text-base font-semibold leading-none md:text-xxl">
            Estude pela área de interesse
          </h3>
        </HeaderSection>
        <section>
          <Container className="py-8">
            <AreasDeInteresse
              areas={
                data.map((a) => ({
                  id: a.id,
                  icone: {
                    url: a.icone?.url!,
                  },
                  titulo: a.titulo!,
                })) as unknown as AreaConhecimento[]
              }
            />
          </Container>
        </section>
      </div>
    </>
  );
};

export default AreasInteressePage;
