import * as React from "react";

import { cn } from "../../utils/utils";
import { Label } from "./label";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
  icon?: React.ReactNode;
  label?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, errorMessage, icon, ...props }, ref) => {
    return (
      <div className="relative flex w-full items-center space-y-2">
        <div className="w-full items-center justify-center">
          {props?.label && (
            <Label
              htmlFor={props.name}
              className="mb-1 select-none  text-xs font-normal text-[#111316]"
            >
              {props.label}
            </Label>
          )}
          <div className="relative flex items-center">
            <input
              type={type}
              id="search"
              className={cn(
                "border-input ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-[50px] w-full rounded-[30px] border bg-[#FAFAFC] px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-base file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:h-[3.125rem]",
                errorMessage ? "border-[1px] border-red-500" : "border-input",
                className,
              )}
              ref={ref}
              aria-label={props?.label ? props?.label : "Buscar"}
              {...props}
            />
            {icon && (
              <div className="absolute right-3 top-1/2 flex -translate-y-1/2 transform flex-wrap items-center justify-center gap-[6px]">
                {icon}
              </div>
            )}
          </div>
          {errorMessage && (
            <div className="mt-1 text-xs text-red-500">{errorMessage}</div>
          )}
        </div>
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input };
